.staff-info-root {
    position: relative;
    top: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 15px rgba(131, 130, 130, 0.337);
    width: fit-content;
    margin-left: 30%;
    font-weight: 400;
    border-radius: 5px;
}

.staff-list {
    position: relative;
    background-color: #DEE2E0;
    max-height: 40em;
    width: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px 10px 10px;
    border-radius: 5px 0 0 5px;
}

.staff-list p {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #B0B0B0;
    border-radius: 5px;
}

.staff-list-title {
    position: sticky;
    top: 0;
    background-color: #DEE2E0;
    width: 100%;
    z-index: 1;
    margin: 0;
}

.list-item-root {
    top: 3em;
    margin: 0;
    border-radius: 5px;
}

@media (max-width: 700px) {
    .work-hours-tag-container {
        display: none;
    }
    
    .staff-info-root {
        width: 100%;
        margin: auto;
    }
    .staff-list {
        width: 100%;
    }
}