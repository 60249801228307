.dropdown-input {
    display: block;
  }

.account-layout {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 500;
  color: #040404;
  /* padding: 10px 20px 17px 10px; */
  margin: 0 0 0 0;
  height: 57px;
  background-color: rgb(233, 230, 226);
  box-shadow: 4px 4px 4px -1px rgba(10, 99, 169, 0.16),
              -4px -4px 4px -1px rgba(255, 255, 255, 0.70);
  z-index: 1;
  width: 100%;
} 

.main-content {
  position: relative;
  top: 9em;
  padding: 15px 120px 15px 120px;
  height: fit-content;
  width: 55em;
  margin: auto;
  box-shadow: 3px 3px 20px rgba(170, 169, 169, 0.337);
  border-radius: 25px;
  /* z-index: -1; */
  background-color: rgb(230, 230, 227);
}

.main-content h2 {
  font-weight: 700;
  font-size: 22px;
}
@media (max-width: 700px) {
  .main-content {
    top: 14em;
    width: fit-content;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.background-img{
  position: absolute;
  top: 5em;
  left: 0;
  background-image: url(./image/peace.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  min-width: 100%;
  border-radius: 5pc 0 0 0;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: hidden;
}
.blank {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  background-color: rgb(249, 240, 238);
  z-index: -1;
}

  .hamburger {
    color: black;
    /* display: inline-block; */
    position: fixed;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 3;
  }
  
  .line {
    margin: 2px 0;
    width: 90%;
    height: 3px;
    background-color: rgb(63, 61, 61);
    transition: transform 0.5s, opacity 0.5s;
  }
  
  .hamburger.active .line:nth-child(1) {
    transform: rotate(38deg);
    transform-origin: 0% 100%;
  }
  
  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active .line:nth-child(3) {
    transform: rotate(-38deg);
    transform-origin: 0% 0%;
  }
#menu-toggle {
  z-index: 1;
  display: none;
  
}
#dropdown-content {
  display: block;
  position: fixed;
  right: 100%;
  top: 3em;
  padding: 17px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 4;
  align-items: left;
  overflow: hidden;
  transition: 0.5s;
  background-color: rgb(212, 217, 215);
  height: fit-content;
  z-index: 9;
}

.circle {
  position: relative;
  top: -5px;
  left: -5px;
  width: 30px;
  height: 30px;
  padding: 6px;
  background-color: white;
  border-radius: 50%;  
}

#dropdown-content button {
  font-weight: 500;
  font-size: 19px;
  text-align: right;
  padding: 10px 15px 10px 15px;
  display: flex;
  width: 12em;
  height: 2.25em;
  overflow: hidden;
  border: none;
  transition-delay: calc(60ms * var(--i));
  margin: 0 auto;
  border-radius: 25px;
  transition: .2s;
  background-color: rgb(222, 226, 224);
  box-shadow: 3px 3px 25px rgba(202, 201, 201, 0.337);
  color: rgb(102, 102, 102);
  transition: .2s;
}

.item.active {
  background-color: #484a4b !important;
  color: white !important;
  transition: .2s;
}

.circle.active {
  background-color: #8cded0;
}

#dropdown-content button:hover {
  width: 12em;
  transition: .2s;
  left: 15px;
  border: 2px solid white;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

#menu-toggle:checked + .hamburger + #dropdown-content {
  right: 0;
  opacity: 1;
}
  
#male-cleaner-premium {
  top: 15em;
  width: 15em;
  height: 20em;
  filter: brightness(1.15);
  position: absolute;
  left: 50%;
  transform: translateX(-50%); /* Center the image horizontally */
  margin-left: 10px;           /* Adjust the left margin as needed */
  margin-right: 10px;

}
/* .account-layout #clean-n-tidy-tittle {
  position: absolute;
  left: 50px;
  top: -15px;
  margin: auto;
  color: white;
  display: block;
  font-family: 'Pacifico', cursive;
  font-size: 25px;
  font-weight: 100;
  background-color: #040404;
} */

.account-layout .tittle-root {
  margin: 0 auto;
}
.account-layout img {
  position: absolute;
  margin: 10px;
}
.account-layout #clean-n-tidy-tittle {
  margin: 5px auto;
  width: fit-content;
  font-family: 'Pacifico', cursive;
  font-size: 25px;
  font-weight: 100;


}

.quick-access-button-container {
  border-radius: 15px;
  background-color: white;
  width: 10em;
  text-align: center;
  margin: 0 auto;
  margin: 5px;
  height: 10em;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: -1;
}

@media (min-width: 800px) {
  .background-img{
    border-radius: 0;
  }
  #dropdown-content button {
    margin-left: 5px;
    margin-right: 5px;
    width: 12em;
    text-align: center;
    opacity: 1;
    border-radius: 25px;
  }

  #dropdown-content {
    left: 10px;
    right: 76%;
    top: 13em;
    opacity: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 4;
    align-items: left;
    overflow: hidden;
    transition: 0.2s;
    border-radius: 25px;
    width: fit-content;
  }

  #menu-toggle:checked + .hamburger + #dropdown-content {
    right: 76%;
    opacity: 1;
    top: 57px;

  }
  .hamburger .line {
    display: none; 
  }

  /* .account-layout #clean-n-tidy-tittle {
    position: absolute;
    right: 35px;
    top: -10px;
    color: white;
    display: block;
  } */
}
@media (min-width: 1250px) {
  #dropdown-content {
    right: 84%;
    width: fit-content;
  }
  #menu-toggle:checked + .hamburger + #dropdown-content {
    right: 84%;
    top: 57px;
  }
  /* .account-layout #clean-n-tidy-tittle {
    position: absolute;
    right: 35px;
    top: -10px;
    color: white;
    display: block;
  } */
}

.welcome-section {
  display: block;
  justify-content: center;
  width: 9em;
  text-align: center;
  line-height: 1;
  /* position: absolute;
  left: 4.4em;
  top: 65px;
  transform: translateX(-50%); */
  /* font-family: 'Architects Daughter', cursive; */
  /* font-family: "Source Serif 4", Georgia, serif; */
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 29px;
  color: white;
  position: relative;
  top: 65px;
  margin: auto;
}

.welcome-date {
  font-size: 22px;
}
@media (max-width: 700px) {
  .welcome-section {
    width: 100vw;
    top: 70px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 27px;
  }
}
.welcome-section #sunWrapper {
  display: inline-block;
  padding: 0;
  padding-top: 4px;
  margin: 0;
  color: yellow;
  animation: rotateSun 6s infinite linear;
}

@keyframes rotateSun {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.convenient-root {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
@media (max-width: 700px) {
  .convenient-root {
    max-width: 19em;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.convenient-root div {
  transition: .2s;
}

@media (min-width: 1000px) {
  .convenient-root div:hover {
    border: 2px solid white;
  }
}
.staff-management-block {
  background-color:rgb(243, 243, 243);
  border-radius: 15px;
  margin: 0 10px 5px 10px;
  padding: 15px;
  height: 7em;
  width: 19em;
  cursor: pointer;
  box-shadow: 3px 3px 7px rgba(0.5, 0.5, 0.5, 0.5);

}

.staff-management-block section {
  background-color: #9ef3a8;
  padding: 10px;
  margin: 0;
  border-radius: 15px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  border: 2px solid white;

}

.add-edit-jobs-block {
  background-color:rgb(243, 243, 243);
  border-radius: 15px;
  margin: 0 10px 5px 10px;
  padding: 15px;
  height: 7em;
  width: 19em;
  cursor: pointer;
  box-shadow: 3px 3px 7px rgba(0.5, 0.5, 0.5, 0.5);

}

.add-edit-jobs-block section {
  background-color: #FCE5BC;
  padding: 10px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid white;
  cursor: pointer;
}

.calendar-schedule-block {
  background-color:rgb(243, 243, 243);
  border-radius: 15px;
  margin: 0 10px 10px 10px;
  padding: 15px;
  height: 7em;
  width: 19em;
  cursor: pointer;
  box-shadow: 3px 3px 7px rgba(0.5, 0.5, 0.5, 0.5);

}

.calendar-schedule-block section {
  background-color: #FCBDA3;
  padding: 10px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid white;
  cursor: pointer;
}
.background-premium {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 12em;
  background-image: url("./image/peace.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.right-block-root {
  margin-left: 10px;
}

@media (max-width: 1000px) {
  .staff-management-block, .add-edit-jobs-block, .calendar-schedule-block {
    margin: 5px 0 5px 0;
  }
  .right-block-root {
    margin: 10px 0 10px 0;
  }
}