.calendar-search-container {
    margin: 10em auto;
}

.task-field.active {
    position: absolute;
    margin: 10px auto;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    top: 0;
    left: 5px;
    background-color: white;
    border-radius: 5px;
    width: 92%;
    box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
    height: 100%;
    overflow-y: auto;
    transition: .5s;
}

.task-field.inactive {
    position: relative;
    padding: 5px;
    margin: 5px auto;
    background-color: white;
    border-radius: 5px;
    width: 19em;
    box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
    height: 300px;
    overflow-y: auto;  
    transition: .5s;
}

.task-header {
    position: sticky;
    width: fit-content;
    height: fit-content;
    margin: 2px;
    top: 5px;
    width: 100%;
    display: flex;
    justify-content: right;
}
.task-header #expand-button {
    width: fit-content;
    height: fit-content;
    border-radius: 25px;
    background-color: white;
    color: blue;
    border: 1px solid blue;
    box-shadow: 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.2);
}

/* .task-field.active .task-header {
    width: 100%;
} */


.task-header h4 {
    top: 0;
}



.date-picker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px auto;
}
.date-picker-container input {
    background-color: transparent;
    border: none;
}
.week-number {
    display: flex;
    margin: 10px auto;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background-color: white;
    max-width: fit-content;
    /* margin-left: 25%;
    margin-right: 25%; */
    border-radius: 25px;
    box-shadow: 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.2);
}

.date-picker-container .MUI-input {
    border-radius: 0 !important;
    background-color: white !important;
}

@media (min-width: 1000px) {
    .calendar-container-parent {
        position: relative;
        display: flex;
        margin: 2em auto;
        width: 70%;
        left: 5em;
    }

    .calendar-container-parent .task-field.inactive {
        margin: 10px;
        background-color: white;
        border-radius: 5px;
        width: 60%;
        box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
        box-sizing: content-box;
        height: 32em;
        overflow-y: auto;  
        transition: .5s;
    }

    .DateCalendar {
        display: grid;
        place-items: center;
    }
    .task-field.active {
        position: absolute;
        padding: 5px;
        background-color: white;
        border-radius: 5px;
        width: 100%;
        box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
        height: 45em;
        overflow-y: auto;
        transition: .5s;
    }
}
