.search-title {
    margin: 10px;
    background-color: rgb(255, 242, 225);
    border-radius: 15px;
}

.search-container.active {
    padding: 0;
    /* display: flex; */
    flex-direction: column;
    width: 16.7em;
    text-align: center;
    background-color: white;
    /* margin: 0 auto; */
    border-radius: 15px;
    position: relative;
    box-shadow: 3px 3px 2px rgba(0.3, 0.3, 0.3, 0.3);
    z-index: 9 !important;
}

.search-container.inactive {
    display: none;
}
.search-content {
    display: inline-block;
    padding: 0;
    z-index: 1;
}
#search-bar {
    /* border: none; */
    min-width: 4em;
    /* max-width: 15em; */
    height: 2.2em;
    margin: 5px;
    font-size: 15px;
    transition: .2s;
}

#search-bar:focus {
    outline: none;
}

#search-icon {
    position: relative;
    margin-left: 0px;
}
#list {
    position: absolute;
    top: 4.5em;
    left: 0;
    max-height: 10em;
    overflow-y: scroll;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    z-index: 2 !important;
    cursor: pointer;
    list-style-type: none;
}

#list.visible {
    display: block;
  }
  
#list.hidden {
display: none;
}


.search-content li {
    border-bottom: 0.5px ridge #c1d7e4;
    margin: 8px 5px 8px 5px;
    list-style-type: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
    z-index: 5;
}

.search-content li:hover {
    background-color: #c9dce8;
    transition: 0.3s;
}


/* main-search-bar */
.main-search-bar {
    position: absolute;
    display: flex;
    top: 10.3em;
    margin: auto;
    /* width: fit-content; */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

.main-search-bar input {
    border-radius: 25px;
    width: 30em;
    height: 5px;
    font-size: 25px;
    padding: 20px;
    border: 1px solid grey;
    font-size: 20px !important;
    box-shadow: 3px 3px 20px rgba(101, 100, 100, 0.337);

}



@media (max-width: 700px) {
    .main-search-bar input{
        width: 12em;
    }
}

.main-search-bar #list {
    top: 33px;
    list-style-type: none;
    cursor: pointer;
}

.main-search-bar li:hover {
    background-color: #c9dce8;
    transition: 0.3s;
}

.main-search-bar #search-icon {
    position: absolute;
    right: 20px !important;
    top: 29px !important;
    color: grey;
}

@media (max-width: 700px) {
    .main-search-bar #search-icon {
        top: 0;
    }
}

@media (min-width: 1000px) {
    #search-bar:hover {
        border: 2px solid rgb(255, 192, 196);
    }
}