.punch-button {
  padding: 10px 20px;
  position: relative;
  margin: 0 auto;
  margin-top: 55px;
  color:rgb(249, 249, 239);
  font-size: large;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid;
  background-color: #ef974f;
  display: block;
  text-align: center;
  box-shadow: 3px 3px 3px -1px rgba(10, 99, 169, 0.16),
              -3px -3px 3px -1px rgba(255, 255, 255, 0.70);
  cursor: pointer;

}
.clockout {
    color: rgb(215, 108, 41);
    font-size: 14px;
    font-weight: 700;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin-top: 35px;
    margin-left: 15%;
    border: none;
    background-color: #f6eded;
    box-shadow: inset 4px 4px 4px -1px rgba(10, 99, 169, 0.16),
              inset -4px -4px 4px -1px rgba(255, 255, 255, 0.70);
    cursor: pointer;

  }
.clockin {
    cursor: pointer;
    color: rgb(75, 192, 36);
    font-size: 14px;
    font-weight: 700;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    border: none;
    background-color: #ecf0ea;
    box-shadow: 3px 3px 3px -1px rgba(10, 99, 169, 0.16),
              -3px -3px 3px -1px rgba(255, 255, 255, 0.70);
  }

.punch-button:active{
  background-color: #fcd89a;
}
.punch-button:hover {
  background-color: #ebc481;
  padding: 10px 26px;
  border-radius: 25px;
  transition: .5s;
}
.clockin:active, .clockout:active {
  background-color: #ffffff;
}
.clockin-clockout {
  position: relative;
  padding: 0 20px 20em 20px;
  /* margin: 10px; */
  height: 15px;
  max-height: fit-content;
  display: block;
  text-align: center;
  background-color:rgb(243, 243, 243);
  border-radius: 15px;
  width: 19em;
  box-shadow: 3px 3px 7px rgba(0.5, 0.5, 0.5, 0.5);
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
  transition: var(--speed) ease;
}

.clockin-clockout p {
  margin: 10px 35px 10px 35px;
}
.clockin-clockout.inactive {
  position: relative;
  margin-top: 25px;
  margin-bottom: 0;
  padding: 0 0 100% 0;
  height: 0;
  display: block;
  text-align: center;
  background-color:#f6f1f1d5;
  border-radius: 15px;
  max-width: 23em;
  margin: 12px auto;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transition: var(--speed) ease;
  z-index: 0;
}
  .status {
    position: relative;
    margin-top: 25px;
    text-align: center;
    font-weight: 600;
    display: block;

  }

@media (max-width: 750px) {
  .clockin-clockout {
    min-height: 23em;
  }
}
  
.calendar-button.inactive {
  visibility: hidden;
}
.go-to-calendar-button {
  color: blue;
  border-radius: 25px;
  
}

