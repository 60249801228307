.register-layout {
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color:#f6f1f1;
    border-radius: 15px;
    width: 20.5em;
  }

  #female-cleaner {
    filter: brightness(1.15);
    display: none;
    padding: 10px;
  }
  @media (min-width: 900px) {
    #female-cleaner {
        width: 25em;
        height: 40em;
        position: relative;
        display: block;
    }
    .register-layout {
        width: 25em;
        height: 40em;
      }
  }
  @media (min-width: 1234px) {
    .register-layout {
      width: 35em;
      min-width: 30%;
      height: 40em;
    }
    .register-frame {
      display: flex;
      margin: 0 auto;
    }
    #female-cleaner {
      width: 27em;
      height: 40em;
    }
  }
@media (min-width: 800px) {
    .register-layout {
        max-width: 30em;
        max-height: 40em;
    }
}
.register-layout form {
    padding: 3% 0 25% 10%;
}
.register-head {
    background-color: #ea9c58ef;
    border-radius: 15px 15px 0 0;
    text-align: center;
    padding: 1px 0 1px 0;
    color: white;
}
.message {
    position: relative;
    left: 1em;
    font-size: 16px;
}