@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

:root {
  --primary-bg: #242526;
  --secondary-bg: #fff;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --speed: 500ms;
  background-color: rgb(210, 210, 206);
}

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

  min-height: 100vh; /* Set a minimum height */
  /* overflow: hidden; */
  /* z-index: -2; */
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-image: url("./image/peace.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.home-image {
  display: flex;
  margin: 0 auto;
  border-radius: 25px;
  min-width: 19em;
  min-height: 5em;
  max-width: 35em;
  max-height: 20em;
  width: 50%;
  height: 25%;
  clip-path: inset(0 0 20% 0);
    box-shadow: 2px 2px 5px rgba(0.3, 0.3, 0.3, 0.3);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home-title {
  font-size: 2.1rem;
  letter-spacing: 0px;
  line-height: 1em;
  width: fit-content;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-roboto);
  --font-roboto: "Roboto Slab", serif;
  --font-raleway: "Raleway", sans-serif;
  position: absolute;
  top: 5.5em;
  text-align: center;
  z-index: 9;
  color: white;
  text-shadow: 2px 2px 0 #e7bd92,
              3px 3px 0 #a37c13;
}
.header {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 63px;
  background-color: rgba(229, 163, 93, 0.884);
  font-size:large;
  box-shadow: 2px 2px 5px rgba(0.3, 0.3, 0.3, 0.3);
  z-index: 2;

}
.header a {
  margin: 0 1em 0 1em;
  font-size: 1em;
  font-weight: 600;
  color:rgb(249, 249, 239);
  text-decoration: none;
  padding: 7% 1% 8% 2%;
}

.header #home-label {
  position: absolute;                                    
  left: 35px;
}

.header #login-label {
  position: absolute;
  right: 35px;
}

.header span {
  position: absolute;
  top: 8px;
  left: 35px;
  width: 9%;

  height: 45px;
  border-bottom: 3px solid #f3d1d1;
  background-clip: padding-box;
  z-index: 0;
  transition: .5s;
}
#home, #login, #register {
  display: none;
  cursor: pointer;
}

#home:checked ~ .indicator {
  left: 35px;
}
#register:checked ~ .indicator {
  left: 45%;
  width: 10%;
}
#login:checked ~ .indicator {
  left: 81%;
  width: 9%;
}

.login-container, .register-container {
  display: flex;
  margin: 2em auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 2px 2px 40px rgba(36, 36, 36, 0.405);
  border-radius: 25px;
}
@media (min-width: 445px) {
  #home:checked ~ .indicator {
    width: 10%;
  }
  #login:checked ~ .indicator {
    left: 83%;
    width: 9%;
  }
}
@media (min-width: 500px) {
  #home:checked ~ .indicator {
    width:9%;
  }
  #login:checked ~ .indicator {
    left: 85%;
    width: 9%;
  }
}
@media (min-width: 600px) {
  #home:checked ~ .indicator {
    width:7%;
  }
  #login:checked ~ .indicator {
    left: 87%;
    width: 8%;
  }
}

/* */
@media (min-width: 680px) {
  #home:checked ~ .indicator {
    width:7%;
  }
  #login:checked ~ .indicator {
    left: 87%;
    width: 10%;
  }
}
@media (min-width: 800px) {
  .home-title {
    top: 6.5em;
  }
  #home:checked ~ .indicator {
    width:6%;
  }
  #login:checked ~ .indicator {
    left: 220px;
    width: 8%;
  }
  #register:checked ~ .indicator {
    left: 120px;
    width: 7%;
  }
  .header #home-label {
    position: absolute;
    left: 35px;
    cursor: pointer;
  }
  .header #register-label {
    position: absolute;
    left: 120px;
    cursor: pointer;
  }
  .header #login-label {
    position: absolute;
    left: 230px;
    cursor: pointer;
  }
  .header #login-label:hover, .header #home-label:hover, .header #register-label:hover {
    transition: 0.5s;
    color: rgb(238, 255, 0);
  }
}

@media (min-width: 1000px) {
  #home:checked ~ .indicator {
    width:5%;
  }
  #login:checked ~ .indicator {
    left: 230px;
    width: 5%;
  }
  #register:checked ~ .indicator {
    left: 130px;
    width: 5%;
  }
}

@media (min-width: 1300px) {
  #home:checked ~ .indicator {
    width:4%;
  }
  #login:checked ~ .indicator {
    left: 230px;
    width: 4%;
  }
  #register:checked ~ .indicator {
    left: 120px;
    width: 4%;
  }
}

.header label {
  color: white;
  font-weight: 700;
}

.header .indicator {
  position: absolute;
  top: 8px;
  left: 40px;
  width: 11%;
  height: 45px;
  border-bottom: 3px solid #f3d1d1;
  background-clip: padding-box;
  z-index: 0;
  transition: 0.5s;
}


.content {
  margin-left: 2em;
  font-size: 1.3em;
}

.message {
  color: red;
  font-size: 0.7em;
}



input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"]{
  box-sizing: border-box;
  width: 90%;
  padding: 6px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 0 20px 20px 20px;
  font-size: 17px;
  background-color: rgb(252, 237, 248);
}

.custom-date-picker-container input[type="text"] {
  width: auto; /* Adjust width or other properties as needed */
  background-color: transparent; /* Remove background color */
  border-radius: 4px; /* You can customize the border radius if you need */
  padding: 10px; /* Adjust padding */
  /* Add other styles specific to the DatePicker input */
}
.MuiPickersCalendarHeader-switchViewButton {
  visibility: visible !important;
}

input[type="submit"] {
  width: 90%;
  /* width: fit-content; */
  height: 2em;
  color:rgb(249, 249, 239);
  font-size: large;
  font-weight: 700;
  /* position: absolute;
  right: 10%; */
  border-radius: 20px;
  border: 1px solid;
  background-color: #e7a22b;
  text-align: center;
  box-shadow: 2px 2px 15px rgba(0.3, 0.3, 0.3, 0.3);
  cursor: pointer;
}
input[type="submit"]:active {
  background-color: #fcd89a;
  cursor: pointer;
}

.login-layout {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  background-color:#ffffff;
  width: 21.5em;
  border-radius: 25px;
}
.login-head {
  max-width: 21.5em;
  border-radius: 25px 25px 0 0;
}
#male-cleaner {
  display: none;
  filter: brightness(1.15);
  padding: 10px;
}

@media (min-width: 800px) {
  .login-layout {
    height: 25em;
  }
  .login-frame {
    display: flex;
  }
  #male-cleaner {
    min-width:17em;
    height: 25em;
    position: relative;
    display: block;
  }
}

@media (min-width: 1000px) {
  .login-frame {
    display: flex;
  }
  #male-cleaner {
    min-width:17em;
    height: 25em;
    position: relative;
    display: block;
  }
}
@media (min-width: 1255px) {
  .login-frame {
    display: flex;
  }
  #male-cleaner {
    min-width:17em;
    height: 25em;
    position: relative;
    left: 5px;
  }
}


.login-head {
  position: relative;
  left: -10px;
  right: -10px;
  background-color: #ea9c58ef;
  margin: auto;
  text-align: center;
  padding: 1px 0 1px 0;
  color: white;
  width: 30em;

}
h5 {
  font-size: 17px;
}


