.work-hours-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40em;
    width: 35em;
    padding: 5px;
    background-color: white;
    border-radius: 0 5px 5px 0;
}
.work-hours-root h2 {
    margin: 0;
    padding: 0;
}
.staff-overview {
    position: absolute;
    top: 2em;
    display: inline;
    background-color: #6ea7df;
    padding: 0 10px 0 10px;
    border-radius: 5px;

}

.filter-options {
    display: flex;
    gap: 10px;
    height: fit-content;
    align-items: center;
}

.filter-options select {
    height: fit-content;
}


.work-hours-date-picker-container {
    position: absolute;
    top: 8em;
}

.work-hours-search-bar-container {
    position: absolute;
    top: 8em;
}

.work-hours-table {
    position: relative;
    top: 8em;
    font-size: 12px;
}


@media (max-width: 700px) {
    .work-hours-root {
        width: 100%;
        position: relative;
        top: 5em;
    }

    .staff-overview {
        padding: 0 25px 0 25px;
        border-radius: 5px;

    }

    .work-hours-table {
        height: 50em;
        top: 15em;
    }
}