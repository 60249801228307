.email-zone {
    background-color: white;
    border-radius: 15px;
    box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
    padding: 10px;
    margin: 10px auto;
    height: 14em;
    max-width: 22em;
}

.code-input-field.active {
    background-color: white;
    border-radius: 15px;
    box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
    padding: 10px;
    margin: 0 auto;
    height: 14em;
    opacity: 1;
    transition: var(--speed) ease;
    z-index: 1;
    max-width: 22em;
}

.code-input-field.inactive {
    background-color: white;
    border-radius: 15px;
    box-shadow: 5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5);
    padding: 10px;
    margin: 0 auto;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: var(--speed) ease;
    z-index: 0;
    max-width: 22em;
}
@media (min-width: 800px) {
    .email-zone {
        max-width: 35em;
    }
    
    .code-input-field.active {
        max-width: 35em;
    }
    
    .code-input-field.inactive {
        max-width: 35em;
    }
}